// perso fita | Pintar pedestal |
import React, { useState, useEffect } from 'react';
import CardCarousel from "../components/CardCarousel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../styles/cardCarousel.css';
import '../styles/rentPage.css';

const RentPage = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const [hrefValues, setHrefValues] = useState({
    "Pedestal inox com corda": 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Prata_Corda?view=month',
    "Pedestal de aço preto com corda": 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Preto_Corda?view=month',
    "Pedestal inox dourado com corda": 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Dourado_Corda?view=month',
    "Pedestal inox com fita retrátil": 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Preto?view=month',
    "Pedestal aço preto com fita retrátil": 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Prata?view=month',
    "Pedestal inox top com corda": 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Top_Prata_Corda?view=month'

  });

  const handleloteChange = (title, selectedlote) => {
    let newHref = hrefValues[title]; 
    switch(title){
      case "Pedestal inox com corda":
        if (selectedlote === "1") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Prata_Corda_-_L1?view=month';
          console.log(newHref)
        } else if (selectedlote === "2") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Prata_Corda_-_L2?view=month';
          console.log(newHref)
        } else if (selectedlote === "3") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Prata_Corda_-_L3?view=month';
          console.log(newHref)
        }
        break;

      case "Pedestal de aço preto com corda":
        if (selectedlote === "1") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Preto_Corda_-_L1?view=month';
          console.log(newHref)
        } else if (selectedlote === "2") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Preto_Corda_-_L2?view=month';
          console.log(newHref)
        } else if (selectedlote === "3") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Preto_Corda_-_L3?view=month';
          console.log(newHref)
        }
        break;

      case "Pedestal inox dourado com corda":
        if (selectedlote === "1") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Dourado_Corda_-_L1?view=month';
          console.log(newHref)
        } else if (selectedlote === "2") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Dourado_Corda_-_L2?view=month';
          console.log(newHref)
        } else if (selectedlote === "3") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Dourado_Corda_-_L3?view=month';
          console.log(newHref)
        }
        break;
      case "Pedestal inox com fita retrátil":
        if (selectedlote === "1") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Prata_-_L1?view=month';
          console.log(newHref)
        } else if (selectedlote === "2") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Prata_-_L2?view=month';
          console.log(newHref)
        } else if (selectedlote === "3") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Prata_-_L3?view=month';
          console.log(newHref)
        }
        break;
      case "Pedestal aço preto com fita retrátil":
        if (selectedlote === "1") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Preto_-_L1?view=month';
          console.log(newHref)
        } else if (selectedlote === "2") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Preto_-_L2?view=month';
          console.log(newHref)
        } else if (selectedlote === "3") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Pedestal_Retratil_Preto_-_L3?view=month';
          console.log(newHref)
        }
        break;
      case "Pedestal inox top com corda":
        if (selectedlote === "P-pedestal") {
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Kit_personalizado?view=month';
          console.log(newHref)
        }
        else if(selectedlote==="P-pedestal+fita"){
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Kit_personalizado?view=month';
          console.log(newHref)
        }
        else if(selectedlote==="P-fita"){
          newHref = 'https://www.supersaas.com.br/schedule/tirasenha/Kit_personalizado?view=month';
          console.log(newHref)
        }
        break; 
      default:
        console.log('Sem produtos selecionados')
           
        
      }
      
      setHrefValues(prev => ({ ...prev, [title]: newHref }));
    };
    const lotes_pedestal_corda_prata = ["1","2","3"];
    const lotes_pedestal_corda_preto = ["1","2","3"];
    const lotes_pedestal_corda_dourado = ["1","2","3"];
    const lotes_pedestal_retratil_prata = ["1","2","3"];
    const lotes_pedestal_retratil_preto = ["1","2","3"];
    const lotes_kit_personalizado=["P-fita","P-pedestal","P-pedestal+fita"]

  const imagens_pedestal_inox_corda = [
    { src: 'https://locacao.tirasenha.com/img/pedestal_inox_prata-cd-dk_cz.webp', alt: 'Pedestal de inox na cor prata com corda cinza escuro' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_inox_prata-cd-cza.webp', alt: 'Pedestal de inox na cor prata com corda cinza' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_inox_prata-cd-pto.webp', alt: 'Pedestal de inox na cor prata com corda preta' },
  ];
  const imagens_pedestal_aco_dourado_corda = [
    { src: 'https://locacao.tirasenha.com/img/pedestal_aco_dourado-cd_vm.webp', alt: 'Pedestal de aço na cor dourada com corda vermelha' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_aco_dourado-cd_vd.webp', alt: 'Pedestal de aço na cor dourada com corda verde' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_aco_dourado-cd_pt.webp', alt: 'Pedestal de aço na cor dourada com corda preta' },
  ];

  const imagens_pedestal_aco_preto_corda = [
    {src:'https://locacao.tirasenha.com/img/pedestal_aco_preto-cd_bc.webp',alt:'Pedestal de aço na cor preta com corda branca'},
    {src:'https://locacao.tirasenha.com/img/pedestal_aco_preto-cd_cz.webp',alt:'Pedestal de aço na cor preta com corda cinza'},
    {src:'https://locacao.tirasenha.com/img/pedestal_aco_preto-cd_pt.webp',alt:'Pedestal de aço na cor preta com corda preta'},
  ]

  const imagens_pedestal_inox_prata_retratil = [
    { src: 'https://locacao.tirasenha.com/img/pedestal_inox_prata-fita-az.webp', alt: 'Pedestal de inox com fita retrátil azul' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_inox_prata-fita-pt.webp', alt: 'Pedestal de inox com fita retrátil preta' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_inox_prata-fita-vm.webp', alt: 'Pedestal de inox com fita retrátil vermelha' },
  ];
  const imagens_pedestal_aco_preto_retratil = [
    { src: 'https://locacao.tirasenha.com/img/pedestal_aco_preto-fita-az.webp', alt: 'Pedestal de aço na cor preta com fita retratil na cor azul' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_aco_preto-fita-pt.webp', alt: 'Pedestal de aço na cor preta com fita retratil na cor preto' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_aco_preto-fita-zb-pt-am.webp', alt: 'Pedestal de aço na cor preta com fita retratil zebrada nas cores preto e amarelo' },
  ];
  const imagens_pedestal_top= [
    { src: 'https://locacao.tirasenha.com/img/pedestal_corda_top_cd-cz.webp', alt: 'Pedestal de inox do modelo top na cor prata com corda na cor cinza ' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_corda_top_cd-pt.webp', alt: 'Pedestal de inox do modelo top na cor prata com corda na cor preta ' },
    { src: 'https://locacao.tirasenha.com/img/pedestal_corda_top_cd-vm.webp', alt: 'Pedestal de inox do modelo top na cor prata com corda na cor vermelha ' }
  ];
  


  return (
    <> 
      <Header />
      <div className='rentCards'>
      <div className="cards-container ">
        <CardCarousel
          title="Pedestal inox com corda"
          description={`Versátil e resistente, ótimo para shoppings e eventos ${!isSmallScreen?"<br>":""} ao ar livre, unindo praticidade a um estilo moderno.`}
          linkText="Clique para reservar o seu agora!!"
          href={hrefValues["Pedestal inox com corda"]} 
          images={imagens_pedestal_inox_corda}
          lotes={lotes_pedestal_corda_prata}
          onloteChange={(lote) => handleloteChange("Pedestal inox com corda", lote)}
        />
        <CardCarousel
          title="Pedestal de aço preto com corda"
          description={`Ideal para eventos corporativos, seu visual moderno e discreto ${!isSmallScreen?"<br>":""}é ideal para controle de acesso em ambientes dinâmicos.`}
          linkText="Clique para reservar o seu agora!!"
          href={hrefValues["Pedestal de aço preto com corda"]} 
          images={imagens_pedestal_aco_preto_corda}
          lotes={lotes_pedestal_corda_preto}
          onloteChange={(lote) => handleloteChange("Pedestal de aço preto com corda", lote)}
        />
        <CardCarousel
          title="Pedestal inox dourado com corda"
          description={`Perfeito para eventos luxuosos, seu design elegante ${!isSmallScreen?"<br>":""}adiciona sofisticação a ambientes formais e exclusivos.`}
          linkText="Clique para reservar o seu agora!!"
          href={hrefValues["Pedestal inox dourado com corda"]} 
          images={imagens_pedestal_aco_dourado_corda}
          lotes={lotes_pedestal_corda_dourado}
          onloteChange={(lote) => handleloteChange("Pedestal inox dourado com corda", lote)}
        />
        </div>
        <hr/> 
        <div className="cards-container">
        <CardCarousel
          title="Pedestal inox com fita retrátil"
          description={`Ideal para filas em aeroportos e eventos, oferece ${!isSmallScreen?"<br>":""}praticidade com um toque moderno e resistente.`}
          linkText="Clique para reservar o seu agora!!"
          href={hrefValues["Pedestal inox com fita retrátil"]}
          images={imagens_pedestal_inox_prata_retratil}
          lotes={lotes_pedestal_retratil_prata} 
          onloteChange={(lote) => handleloteChange("Pedestal inox com fita retrátil", lote)}
        />
        <CardCarousel
          title="Pedestal aço preto com fita retrátil"
          description={`Funcional e discreto, perfeito para controle de fluxo em ${!isSmallScreen?"<br>":""}eventos e espaços corporativos, garantindo eficiência.`}
          linkText="Clique para reservar o seu agora!!"
          href={hrefValues["Pedestal aço preto com fita retrátil"]}
          images={imagens_pedestal_aco_preto_retratil}
          lotes={lotes_pedestal_retratil_preto} 
          onloteChange={(lote) => handleloteChange("Pedestal aço preto com fita retrátil", lote)}
        />
        <CardCarousel
          title="Pedestal inox top com corda"
          description={`Sofisticado e resistente, ideal para organização de filas em ${!isSmallScreen?"<br>":""}eventos, recepções e ambientes corporativos.`}
          linkText="Clique para reservar o seu agora!!"
          href={hrefValues["Pedestal inox top com corda"]}
          images={imagens_pedestal_top}
          lotes={lotes_kit_personalizado} 
          onloteChange={(lote) => handleloteChange("Pedestal inox top com corda", lote)}
        />
      </div>
      </div>
      <Footer />
    </>
  );
};

export default RentPage;
